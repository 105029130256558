.post-page {
  padding-top: 40px;

  .header {
    @media (max-width: 1024px) {
      padding: 0 !important;
    }
  }

  .post-page-section_intro {
    padding: 0 40px 0 40px;
    // margin-bottom: 75px;

    .intro-content {
      .title {
        @include fontLarge($ringside, 700);
        font-size: 86px;
        line-height: 86px;
        text-align: center;
        text-transform: none;
        color: $blue;

        @media (min-width: 1025px) {
          padding: 0 12%;
        }
      }

      .date {
        @include fontDefault($archer, 400);
        font-size: 16px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $gold;
      }
    }

    .header {
      .nav-link {
        color: $blue;
        background-color: $off_white;
      }
      .donate-button {
        color: $blue;
        background-color: $off_white;

        &:hover {
          color: $white;
          background-color: $gold;
        }
      }
    }
  }

  .post-page-section_body {
    padding: 0 40px 90px;

    .post-content {
      h2 {
        @include fontMedium($mercury, 400);
        font-size: 35px;
        line-height: 45px;
        color: $blue;
        text-align: left;
        display: block;

        @media (min-width: 1025px) {
          width: 40%;
          margin: 50px auto 60px;
        }
      }

      .callout {
        @include fontMedium($ringside, 400);
        font-size: 34px;
        line-height: 39px;
        font-weight: 700;
        color: $blue;

        &:before {
          display: block;
          content: "";
          border-bottom: solid 1px $gold;
          margin-bottom: 20px;
        }
      }

      p {
        @include fontDefault($gotham, 400);
        font-size: 14px;
        line-height: 25px;
        color: $blue;
        text-align: left;

        @media (min-width: 1025px) {
          width: 40%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .wp-block-image {
        img {
          max-width: 100%;
          height: auto;
          padding: 90px 0;
        }
      }

      .wp-block-columns {
        @media (min-width: 1025px) {
          display: flex;
          justify-content: center;

          .wp-block-image {
            margin: auto 10px;
          }
        }
      }
    }
  }

  .post-page-section_testimonial {
    .testimonial-bg {
      aspect-ratio: 475 / 207;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
      padding: 0;
      display: flex;
      align-items: center;

      .testimonials {
        background-color: transparent;

        .testimonial-block {
          @media (min-width: 1025px) {
            padding: 0;
          }
        }
      }
    }
  }

  .post-page-section_additional-updates {
    padding: 90px 40px;
    background-color: $off_white;
  }

  .post-page-section_wrapup {
    .content {
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }

    .wrapup-bg {
      display: flex;
      flex-direction: column;
      justify-content: end;
      aspect-ratio: 16/19;
      background-size: contain;
      background-repeat: no-repeat;

      @media (min-width: 1025px) {
        aspect-ratio: 360 / 167;
        justify-content: center;
      }

      img {
        max-width: 100%;
      }

      .words-section {
        background-color: $blue;
      }

      p {
        @include fontLarge($mercury, 500);
        font-size: 17px;
        line-height: 30px;
        margin-top: 5px;
        margin-bottom: 15px;

        @media (min-width: 1025px) {
          font-size: 28px;
          margin-top: 18px;
          margin-bottom: 28px;
        }

        &.title {
          @include fontLarge($gotham, 700);
          font-size: 30px;
          margin-top: 30px;
          margin-bottom: 0;
          letter-spacing: 5px;

          @media (min-width: 1025px) {
            font-size: 30px;
            margin-top: 0;
          }

          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
  }
}
