.about {
  .about-section_hero {
    .hero-bg {
      // aspect-ratio: 1440 / 793;
      aspect-ratio: 1433 / 793;
      background-size: contain;
      background-repeat: no-repeat;
      // z-index: -999;
      padding: 40px 40px 0 40px;
      position: relative;

      @media (max-width: 1024px) {
        aspect-ratio: 1433 / 807;
      }

      .heading {
        @include fontLarge($ringside, 700);
        font-size: 30px;

        @media (min-width: 1025px) {
          font-size: 95px;
          left: 15%;
        }

        text-transform: uppercase;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 45px;
        position: absolute;
        bottom: 0;
        // width: 100%;
        left: 11%;
      }
    }
  }

  .about-section_intro {
    .about-message {
      // aspect-ratio: 180 / 107;
      aspect-ratio: 178 / 107;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
      padding: 40px 40px 0 40px;

      .columns {
        @media (max-width: 1024px) {
          display: block;
        }

        @media (min-width: 1025px) {
          padding: 120px 240px;
        }

        .left {
          @media (max-width: 1024px) {
            margin-bottom: 70px;
          }

          .blurb {
            padding-bottom: 45px;

            h2 {
              margin-top: 0;
              @include subheadFont();
              font-size: 30px;
              text-align: left;
            }
          }

          .pillars {
            max-width: 300px;

            .pillars-title {
              @include calloutFont();
              text-align: left;
              color: $gold;
              padding-bottom: 20px;
            }

            .pillars-types {
              // .pillar-link {
              a {
                text-decoration: none;
              }
              p {
                @include fontDefault($mercury, 400);
                font-size: 19px;
                color: $blue;
                text-align: left;
                padding-bottom: 1rem;
                border-bottom: 1px solid $gold;

                &:nth-child(1) {
                  margin-top: 0;
                }
              }
            }
          }
        }

        .right {
          position: relative;

          p {
            @include bodyFont();
            font-size: 16px;
            text-align: left;

            &:nth-child(1) {
              margin-top: 0;
            }

            &:nth-child(3) {
              margin-bottom: 0;
            }
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -1.5rem;
            width: 1px;
            height: 100%;
            background-color: $gold;
          }
        }
      }
    }
  }

  .about-section_capital-improvements {
    .capital-bg {
      aspect-ratio: 9 / 5;
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 70px 0;

      @media (min-width: 1025px) {
        padding: 0;
      }

      .capital-content {
        display: flex;
        justify-content: center;

        h1 {
          @include subheadFont();
          font-size: 50px;
          @media (min-width: 1025px) {
            font-size: 70px;
          }
          line-height: 65px;
        }
      }

      .goal {
        @include calloutFont();
        font-size: 12px;
        text-align: left;
        color: $gold;
        padding-bottom: 20px;
      }

      .amount {
        @include calloutFont();
        font-size: 12px;
        font-weight: 700;
        color: $blue;
      }
    }
  }

  .about-section_objective-1 {
    .objective-1-bg {
      aspect-ratio: 1440 / 950;
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .obj-1-blurb {
        margin: 35px 60px;

        @media (min-width: 1025px) {
          margin: 0 430px;
        }

        position: relative;

        p {
          @include fontDefault($gotham, 400);
          text-align: left;
          font-size: 16px;
          line-height: 32px;
          margin: 0;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -1.5rem;
          width: 1px;
          height: 100%;
          background-color: $light_gold;
        }
      }
    }
  }

  .about-section_classroom-reno {
    .reno-content {
      @media (min-width: 1025px) {
        aspect-ratio: 480 / 446;//480 / 449;
      }
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      padding: 60px;

      @media (max-width: 1023px) {
        .columns {
          display: block;
        }
      }

      .left {
        flex-basis: 33%;
        max-width: 380px;

        .reno-subhead {
          @include calloutFont();
          text-align: left;
          color: $gold;
          padding-top: 25px;

          &.top-border {
            border-top: 3px solid $gold;
          }
        }

        .reno-title {
          @include fontDefault($archer, 400);
          font-size: 55px;
          line-height: 60px;
          color: $blue;
          text-align: left;
          margin: 0;
        }

        .blurb {
          @include fontSmall($gotham, 400);
          font-size: 14px;
          color: $blue;
          line-height: 28px;
          text-align: left;
        }

        .learn-more {
          padding: 25px 0;
          @include fontSmall($gotham, 400);
          font-size: 14px;
          line-height: 28px;
          color: $blue;
          text-align: left;
        }

        .updates {
          max-width: 300px;

          .pillars-title {
            @include calloutFont();
            text-align: left;
            color: $gold;
            padding-bottom: 20px;
          }

          .reno-types {
            p {
              @include fontDefault($archer, 400);
              font-size: 19px;
              color: $blue;
              text-align: left;
              padding-bottom: 1rem;
              border-bottom: 1px solid $gold;

              &:nth-child(1) {
                margin-top: 0;
              }
            }
          }
        }

        .donate-button {
          @include linkFont();
          display: flex;
          align-items: center;
          gap: 17px;
          color: $blue;
          font-size: 20px;
          font-weight: 700;

          border: 2px #c89211 solid;
          border-radius: 30px;
          padding: 10px;
          max-width: max-content;

          &:hover {
            color: $gold;
          }
        }
      }

      .right {
        flex-basis: 66%;

        .reno-media {
          img,
          video {
            max-width: 100%;
          }
        }
      }
    }
  }

  .about-section_message {
    .message-bg {
      aspect-ratio: 144 / 95;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
      padding: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      .message {
        @include fontLarge($archer, 400);
        font-size: 30px;

        @media (min-width: 1025px) {
          font-size: 73px;
        }
      }
    }
  }

  .about-section_student-center {
    .student-center-bg {
      @media (min-width: 1025px) {
        //aspect-ratio: 277 / 250;//480 / 499;
      }
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      background-color: #FFFCF7;
      z-index: -999;
      display: flex;

      .columns {
        padding: 40px;

        @media (max-width: 1024px) {
          display: block;
        }

        @media (min-width: 1025px) {
          padding: 60px;
        }
      }

      .left {
        flex-basis: 66%;

        .reno-media {
          img,
          video {
            max-width: calc(100% - 60px);
          }
        }
      }

      .right {
        flex-basis: 33%;

        .comunity-container {
          @media (max-width: 1024px) {
            padding-top: 40px;
          }
        }

        .reno-subhead {
          @include calloutFont();
          text-align: left;
          color: $gold;
          padding-top: 25px;
          border-top: 3px solid $gold;

          .top-border {
            border-top: 3px solid $gold;
          }
        }

        .reno-title {
          @include fontDefault($archer, 400);
          font-size: 55px;
          line-height: 60px;
          color: $blue;
          text-align: left;
          margin: 0;
        }

        .blurb {
          @include fontSmall($gotham, 400);
          font-size: 14px;
          line-height: 28px;
          color: $blue;
          text-align: left;
        }

        .learn-more {
          padding: 25px 0;
          @include fontSmall($gotham, 400);
          font-size: 14px;
          line-height: 28px;
          color: $blue;
          text-align: left;
        }

        .updates {
          max-width: 300px;

          .pillars-title {
            @include calloutFont();
            text-align: left;
            color: $gold;
            padding-bottom: 20px;
          }

          .reno-types {
            p {
              @include fontDefault($archer, 400);
              font-size: 19px;
              color: $blue;
              text-align: left;
              padding-bottom: 1rem;
              border-bottom: 1px solid $gold;

              &:nth-child(1) {
                margin-top: 0;
              }
            }
          }
        }

        .donate-button {
          @include linkFont();
          display: flex;
          align-items: center;
          gap: 17px;
          color: $blue;
          font-size: 20px;
          font-weight: 700;

          border: 2px #c89211 solid;
          border-radius: 30px;
          padding: 10px;
          max-width: max-content;

          &:hover {
            color: $gold;
          }
        }
      }
    }

    .campus-design-bg {
      aspect-ratio: 40 / 27;//9 / 5;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
    }

    .shield-section {
      background-color: $off_white;

      .columns {
        padding: 40px;

        @media (min-width: 1025px) {
          padding: 240px;
        }
      }

      img {
        max-width: 100%;
      }

      p {
        @include fontSmall($gotham, 400);
        font-size: 16px;
        line-height: 32px;
        color: $blue;
        text-align: left;
        padding-bottom: 50px;
      }

      .donate-button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include fontSmall($archer, 400);
        height: 92px;
        width: 92px;
        border-radius: 50%;
        color: $blue;
        background-color: transparent;
        outline: none;
        transition: all 0.2s ease-in;
        border: 2px $gold solid;
        text-decoration: none;

        &:hover {
          background-color: $gold;
        }
      }
    }
  }

  .about-section_endowment-growth {
    .endowment-bg {
      aspect-ratio: 9 / 5;
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 70px 0;

      @media (min-width: 1025px) {
        padding: 0;
      }

      .endowment-content {
        display: flex;
        justify-content: center;

        h1 {
          @include subheadFont();
          font-size: 50px;

          @media (min-width: 1025px) {
            font-size: 70px;
          }
          line-height: 65px;
        }
      }

      .goal {
        @include calloutFont();
        font-size: 12px;
        text-align: left;
        color: $gold;
        padding-bottom: 20px;
      }

      .amount {
        @include calloutFont();
        font-size: 12px;
        font-weight: 700;
        color: $blue;
      }
    }
  }

  .about-section_objective-2 {
    .objective-2-bg {
      aspect-ratio: 1440 / 950;
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .obj-2-blurb {
        margin: 35px 60px;

        @media (min-width: 1025px) {
          margin: 0 430px;
        }

        position: relative;

        p {
          @include fontDefault($gotham, 400);
          text-align: left;
          font-size: 16px;
          line-height: 32px;
          margin: 0;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -1.5rem;
          width: 1px;
          height: 100%;
          background-color: $light_gold;
        }
      }
    }
  }

  .about-section_financial-aid {
    padding: 40px;

    @media (min-width: 1025px) {
      padding: 120px 280px;
    }

    .columns {
      padding-top: 70px;

      .left {
        p {
          @include fontSmall($gotham, 400);
          font-size: 16px;
          line-height: 32px;
          text-align: left;
          color: $blue;
        }
      }

      .right {
        .fin-subhead {
          @include calloutFont();
          text-align: left;
          color: $gold;
          padding-top: 25px;
          border-top: 3px solid $gold;
        }

        .fin-title {
          @include fontDefault($archer, 400);
          font-size: 55px;
          line-height: 60px;
          color: $blue;
          text-align: left;
          margin: 0;
        }

        .blurb {
          padding-bottom: 45px;
          @include fontSmall($gotham, 400);
          font-size: 14px;
          line-height: 28px;
          color: $blue;
          text-align: left;
        }

        .learn-more {
          padding-top: 45px;
          @include fontSmall($gotham, 400);
          font-size: 14px;
          color: $blue;
          text-align: left;
        }

        .updates {
          max-width: 300px;

          .pillars-title {
            @include calloutFont();
            text-align: left;
            color: $gold;
            padding-bottom: 20px;
          }

          .reno-types {
            p {
              @include fontDefault($archer, 400);
              font-size: 19px;
              color: $blue;
              text-align: left;
              padding-bottom: 1rem;
              border-bottom: 1px solid $gold;

              &:nth-child(1) {
                margin-top: 0;
              }
            }
          }
        }

        .donate-button {
          @include linkFont();
          display: flex;
          align-items: center;
          gap: 17px;
          color: $blue;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }

  .about-section_wrapup {
    .content {
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }
    .wrapup-bg {
      display: flex;
      flex-direction: column;
      justify-content: end;
      aspect-ratio: 16/19;
      background-size: contain;
      background-repeat: no-repeat;

      @media (min-width: 1025px) {
        aspect-ratio: 360 / 167;
        justify-content: center;
      }

      img {
        max-width: 100%;
      }

      .words-section {
        background-color: $blue;
      }

      p {
        @include fontLarge($mercury, 500);
        font-size: 17px;
        line-height: 30px;
        margin-top: 5px;
        margin-bottom: 15px;

        @media (min-width: 1025px) {
          font-size: 28px;
          margin-top: 18px;
          margin-bottom: 28px;
        }

        // @media (max-width: 1024px) {
        //   margin-bottom: 20px;
        //   margin-top: 0;
        // }

        &.title {
          @include fontLarge($gotham, 700);
          font-size: 30px;
          margin-top: 30px;
          margin-bottom: 0;
          letter-spacing: 5px;

          @media (min-width: 1025px) {
            font-size: 30px;
            margin-top: 0;
          }

          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
  }
}
