.campaign-updates {
  padding-top: 40px;

  .header {
    @media (max-width: 1024px) {
      padding: 0 !important;
    }
  }

  .campaign-updates-section_intro {
    padding: 0 40px 0 40px;

    .heading {
      @include fontLarge($ringside, 700);
      font-size: 65px;
      color: $blue;
      text-transform: uppercase;
      border-bottom: 1px solid $gold;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 90px;

      @media (min-width: 1025px) {
        width: max-content;
      }
    }

    .header {
      .nav-link {
        color: $blue;
        background-color: $off_white;
      }
      .donate-button {
        color: $blue;
        background-color: $off_white;

        &:hover {
          color: $white;
          background-color: $gold;
        }
      }
    }

    .list-of-posts {
      .load-more-container {
        display: flex;
        justify-content: center;
        margin: 75px -40px 0;
        background-color: $off_white;
      }

      .end-of-results {
        @include linkFont();
        font-size: 17px;
        line-height: 32px;
        color: $blue;
        font-weight: 700;
        padding: 10px 25px;
        text-transform: uppercase;
        margin: 75px 0;
      }

      .cta-button {
        &.load-more {
          padding: 10px 25px;
          margin: 75px 0;
        }
      }
    }
  }

  .campaign-updates-section_additional-posts {
    padding: 90px 40px;
    background-color: $off_white;
  }

  .campaign-updates-section_wrapup {
    .content {
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }

    .wrapup-bg {
      display: flex;
      flex-direction: column;
      justify-content: end;
      aspect-ratio: 16/19;
      background-size: contain;
      background-repeat: no-repeat;

      @media (min-width: 1025px) {
        aspect-ratio: 360 / 167;
        justify-content: center;
      }

      img {
        max-width: 100%;
      }

      .words-section {
        background-color: $blue;
      }

      p {
        @include fontLarge($mercury, 500);
        font-size: 17px;
        line-height: 30px;
        margin-top: 5px;
        margin-bottom: 15px;

        @media (min-width: 1025px) {
          font-size: 28px;
          margin-top: 18px;
          margin-bottom: 28px;
        }

        &.title {
          @include fontLarge($gotham, 700);
          font-size: 30px;
          margin-top: 30px;
          margin-bottom: 0;
          letter-spacing: 5px;

          @media (min-width: 1025px) {
            font-size: 30px;
            margin-top: 0;
          }

          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
  }
}

.featured-posts {
  padding: 0;

  @media (min-width: 1025px) {
    padding: 0 85px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .list-item {
    flex-basis: 30%;
    margin: 50px auto;

    @media (min-width: 1025px) {
      width: 90%;
    }

    .featured-link {
      text-decoration: none;

      .post {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        text-align: left;

        .date {
          @include fontDefault($gotham, 400);
          font-size: 14px;
          line-height: 25px;
          color: rgba(0, 48, 87, 0.5);
          margin-bottom: 0;
        }

        .title {
          @include fontDefault($ringside, 600);
          font-size: 28px;
          line-height: 30px;
          color: $blue;
          flex: 1;
          margin-top: 0;
        }

        .link {
          @include fontDefault($archer, 400);
          font-size: 19px;
          line-height: 35px;
          letter-spacing: 0.05em;
          text-decoration: none;
          color: #003057;
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 19px;
        }

        .img-box {
          background-position-x: center;
          background-position-y: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          transition: all 0.3s ease-in-out;
          height: 385px;
          width: 100%;
        }

        &:hover {
          .img-box {
            background-size: auto 110%;
            opacity: 0.9;
          }

          .cta-button {
            color: $white;
            background-color: $gold;
            img {
              content: url("../../images/icons/right-arrow_white.svg");
            }
          }
        }
      }
    }
  }
}

.uneven-columns {
  text-align: left;

  @media (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
    padding: 0 85px;
  }

  .left {
    flex-basis: 25%;

    p {
      @include calloutFont();
      color: $gold;
    }
  }

  .right {
    flex-basis: 65%;

    .additional-posts {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;

      .list-item:first-child .additional-link .post {
        padding: 0 0 30px 0;
      }

      .list-item {
        .additional-link {
          text-decoration: none;

          .post {
            display: flex;
            justify-content: space-between;
            text-align: left;
            padding: 30px 0;

            .date {
              @include fontDefault($gotham, 400);
              font-size: 14px;
              line-height: 25px;
              color: rgba(0, 48, 87, 0.5);
              margin-bottom: 0;
            }

            .title {
              @include fontDefault($ringside, 600);
              font-size: 28px;
              line-height: 30px;
              color: $blue;
              margin-top: 0;
            }

            .link {
              @include fontDefault($archer, 400);
              font-size: 19px;
              line-height: 35px;
              letter-spacing: 0.05em;
              text-decoration: none;
              color: #003057;
              display: flex;
              align-items: center;
              justify-content: start;
              gap: 19px;
            }

            .img-box {
              background-position-x: center;
              background-position-y: center;
              background-repeat: no-repeat;
              background-size: 100% auto;
              transition: all 0.3s ease-in-out;
              width: 100%;
              flex-basis: 20%;
            }

            .info {
              flex-basis: 70%;
            }

            &:hover {
              .img-box {
                background-size: 110% auto;
                opacity: 0.9;
              }

              .cta-button {
                color: $white;
                background-color: $gold;
                img {
                  content: url("../../images/icons/right-arrow_white.svg");
                }
              }
            }
          }

          &:after {
            display: block;
            content: "";
            border-top: solid 1px $gold;
            opacity: 0.25;
          }
        }

        &:last-child a:after {
          border: none;
        }
      }
    }
  }
}
