.page-content {
  &.wrapper {
    &.home-section_intro {
      padding: 40px;

      @media (min-width: 1025px) {
        padding: 165px 40px;
      }
      position: relative;

      .left-shield {
        position: absolute;
        top: 0;
        left: 0;
      }
      .right-shield {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .content {
        .intro-text-section {
          @media (min-width: 1025px) {
            width: 40%;
          }

          margin: 0 auto;
          text-align: left;

          a {
            margin-top: 45px;
          }

          .right-border-block {
            position: relative;

            h2 {
              @include subheadFont();
            }

            p {
              @include bodyFont();
            }

            @media (min-width: 1025px) {
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -1.5rem;
                width: 1px;
                height: 100%;
                background-color: $gold;
              }
            }
          }
        }
      }
    }

    &.home-section_featured {
      .featured-update {
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position-x: center;
        background-position-y: center;
        padding: 50px 40px;

        @media (min-width: 1025px) {
          height: 350px;
        }

        transition: all 0.3s ease-in-out;
        &:hover {
          background-size: auto 110%;
          opacity: 0.9;
        }

        .callout {
          @include calloutFont();
          color: $white;
          text-align: left;

          .light {
            color: $light_gold;
          }
        }

        .featured-details {
          @media (min-width: 1025px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 70%;
          }

          .description {
            text-align: left;

            @media (min-width: 1025px) {
              width: 35%;
            }

            h2 {
              @include subheadFont();
              font-size: 50px;
              line-height: 47px;
              color: $white;
              margin: 90px auto 0;

              @media (min-width: 1025px) {
                margin: 0;
              }
            }

            p {
              @include bodyFont();
              color: $white;
            }
          }

          .learn-more {
            padding-right: 90px;
            border: 2px #c89211 solid;
            border-radius: 30px;
            padding: 10px;

            .learn-more-link {
              @include linkFont();
              color: $white;
              display: flex;
              align-items: center;
              gap: 17px;

              &:hover {
                color: $gold;
              }
            }
          }
        }
      }
    }

    &.home-section_letter {
      padding: 50px 40px;

      @media (min-width: 1025px) {
        padding: 133px 275px;
      }

      position: relative;

      .vertical-title {
        @include calloutFont();
        color: $blue;
        position: absolute;
        top: 255px;
        left: -4%;
        transform: rotate(90deg);
      }

      .letter-columns {
        @media (min-width: 1025px) {
          display: flex;
          gap: 111px;
        }

        .letter-text {
          @include bodyFont();
          text-align: left;

          p {
            margin-bottom: 1.5rem;
          }

          .signature {
            margin-top: 25px;
          }
        }

        .president {
          position: relative;

          img {
            max-width: 200px;

            @media (max-width: 1024px) {
              margin-top: 50px;
            }
          }

          .president-info {
            position: relative;
            text-align: left;

            .name {
              @include fontDefault($ringside, 600);
              font-size: 22px;
              line-height: 25px;
              color: $blue;
              padding-top: 6px;
            }

            .job {
              position: relative;

              .title {
                @include calloutFont();
                line-height: 25px;
                color: $blue;
                margin: 0;
              }

              .school {
                @include bodyFont();
                font-size: 10px;
                line-height: 5px;
                margin: 0;
              }

              &:before {
                content: "";
                position: absolute;
                top: -7px;
                left: 0;
                width: 17px;
                height: 1px;
                background-color: $blue;
              }
            }

            &:before {
              content: "";
              position: absolute;
              top: -7px;
              left: 0;
              width: 100%;
              height: 3px;
              background-color: $blue;
            }
          }

          .seal {
            position: absolute;
            bottom: 0;
            left: 30%;
          }
        }
      }
    }

    &.home-section_snapshot {
      .snapshot-bg {
        background-size: contain;
        background-repeat: no-repeat;
        aspect-ratio: 360 / 212;

        position: relative;
      }
      .campaign-snapshot {
        padding: 50px 40px;

        @media (min-width: 1025px) {
          padding: 85px 125px;
        }

        .amount-container {
          .snapshot-header {
            display: flex;
            justify-content: space-between;

            p {
              @include calloutFont();
            }

            .donate-button {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              @include fontSmall($archer, 400);
              height: 92px;
              width: 92px;
              border-radius: 50%;
              color: $white;
              background-color: transparent;
              outline: none;
              transition: all 0.2s ease-in;
              border: 2px $gold solid;
              text-decoration: none;

              &:hover {
                background-color: $gold;
                color: $white;
              }
            }
          }

          .current-amount {
            @include fontXLarge($archer, 300);
            font-size: 75px;
            line-height: 75px;
            color: $gold;
            text-align: left;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: -5%;
            padding-bottom: 50px;

            @media (min-width: 1025px) {
              padding-bottom: 0;
              font-size: 215px;
              line-height: 200px;
            }

            @media (min-width: 1281px) {
              font-size: 267px;
              line-height: 247px;
            }
          }
        }

        .campaign-stats {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          border-top: 1px solid $gold;

          @media (min-width: 1025px) {
            flex-wrap: nowrap;
            gap: 25px;
            position: absolute;
            bottom: 50px;
            width: calc(100% - 250px);
          }

          .stat {
            .value {
              @include fontDefault($ringside, 400);
              font-size: 25px;
            }

            .name {
              @include fontDefault($mercury, 400);
              font-size: 14px;
              letter-spacing: 0.1em;
            }
          }
        }
      }
    }

    &.home-section_tagline-slider {
      .slide-on-scroll {
        width: 100%;
        overflow: hidden;

        .slider-text {
          @include fontDefault($mercury, 400);
          font-size: 30px;
          @media (min-width: 1025px) {
            font-size: 50px;//120px;
            line-height: 55px;
          }
          line-height: 145px;
          color: $blue;//$gold;
          white-space: nowrap;
          overflow: hidden;

          margin-top: 300px;
          margin-bottom: 0;//30px;
        }
      }

      .slidee-box {
        padding: 80px 0;
        width: 100%;
        overflow: hidden;

        .slidee {
          @include fontDefault($mercury, 400);
          font-size: 120px;
          line-height: 145px;
          color: $gold;
          white-space: nowrap;
          overflow: hidden;
          margin: 0;
          transform: translateX(-100%);
          animation: slidee 20s infinite linear;
        }

        &:hover {
          .slidee {
            color: $white;
          }
          background-color: $gold;
        }

        @keyframes slidee {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      }
    }

    &.home-section_numbers-testimonials {
      .content {
        .numbers {
          padding: 50px 40px;

          @media (min-width: 1025px) {
            padding: 0px 125px 150px;
          }

          .numbers-title {
            @include fontDefault($mercury, 500);
            font-size: 30px;
            line-height: 30px;
            color: $gold;//$blue;
          }

          .numbers-row {
            @media (min-width: 1025px) {
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              padding: 110px 50px 15px;
            }

            &.middle {
              justify-content: space-around;
            }

            .stat {
              display: flex;
              flex-direction: column;
              align-items: center;

              @media (max-width: 1024px) {
                padding-bottom: 30px;
              }

              .numerical {
                @include fontDefault($archer, 400);
                font-size: 95px;
                line-height: 75px;
                letter-spacing: -0.02em;
                color: $blue;
              }

              .description {
                @include fontSmall($ringside, 400);
                font-size: 15px;
                line-height: 17px;
                color: $blue;
                padding-top: 13px;
                max-width: 165px;
              }
            }
          }
        }

        .link-container {
          height: 185px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $cream;

          .donor-voices-link {
            @include linkFont();
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 17px;
          }
        }
      }
    }

    &.home-section_final-section {
      background-color: $gold;
      .content {
        .cta-container {
          padding: 120px 0 110px;
          width: 70%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 50px;

          .anchor-icon {
            margin-bottom: 1rem;

            @media (min-width: 1025px) {
              margin-bottom: 6rem;
            }
          }

          .final-blurb {
            @include fontMedium($mercury, 400);
            font-size: 25px;
            @media (min-width: 1025px) {
              font-size: 45px;
            }
            line-height: 66px;
            margin: 0;
            max-width: 900px;
          }

          // .final-cta {
          //   @include linkFont();
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   gap: 17px;
          //   color: $blue;
          //   font-size: 20px;
          //   font-weight: 700;

          //   background-color: $white;
          //   border: 2px $gold solid;
          //   border-radius: 30px;
          //   padding: 10px 15px;
          //   max-width: max-content;

          //   &:hover {
          //     color: $white;
          //     background-color: $gold;
          //   }
          // }
        }

        .image-slider-container {
          height: 350px;
          position: relative;
          overflow: hidden;
          padding-bottom: 90px;

          .image-slider {
            position: absolute;
            top: 0px;
            left: 0px;
            overflow: hidden;
            white-space: nowrap;
            height: 85%;
            animation: slide-images 60s linear infinite;

            .shield {
              height: 100%;
              margin: 0 2rem;
            }

            @keyframes slide-images {
              0% {
                transform: translate(0, 0);
              }
              100% {
                transform: translate(-50%, 0);
              }
            }
          }
        }
      }
    }
  }
}
