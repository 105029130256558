.columns {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
  margin: 0 auto;

  &.full {
    margin: 50px 40px 0;
    width: 100%;
  }

  .column {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;

    @media (min-width: $min_laptop) {
      flex: 0 0 calc(50% - 1.5rem);

      &.full {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
