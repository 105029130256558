.home {
  .hero {
    height: 100%;
    position: relative;

    .bg-animation-container {
      aspect-ratio: 16 / 9;
    }

    .bg-container {
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -999;

      video {
        width: 100%;
      }

      // animation-name: fade;
      // animation-timing-function: ease-in-out;
      // animation-iteration-count: infinite;
      // animation-duration: 24s;

      // &:nth-of-type(1) {
      //   animation-delay: 18s;
      // }
      // &:nth-of-type(2) {
      //   animation-delay: 12s;
      // }
      // &:nth-of-type(3) {
      //   animation-delay: 6s;
      // }
      // &:nth-of-type(4) {
      //   animation-delay: 0s;
      // }

      // .shield {
      //   height: 75%;
      //   margin: 0 auto;
      //   position: relative;
      //   transform: translateY(25%);
      // }
    }

    // @keyframes fade {
    //   0% { opacity: 1; }
    //   21% { opacity: 1; }
    //   25% { opacity: 0; }
    //   96% { opacity: 0; }
    //   100% { opacity: 1; }
    // }

    .hero-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1rem;

      @media (min-width: $min_desktop) {
        padding: 36px 40px 0;
      }

      .archbishop-text {
        @include calloutFont();
        position: absolute;
        top: 50%;
        right: -4%;
        transform: rotate(90deg);
      }

      .title {
        position: absolute;
        bottom: 7%;
        @include titleFont();
        margin: 0;
        padding-left: 85px;
        z-index: 2;
      }
    }
  }

  .mobile-title {
    h1 {
      @include titleFont();
      font-size: 3rem;
      padding-left: 20px;
      color: $blue;
      margin-bottom: 0;
    }
  }

  .home-section_pillars {
    padding: 50px 40px 0;
    background-color: $off_white;

    @media (min-width: 1025px) {
      padding: 130px 125px 0;
    }

    .pillars-columns {
      min-height: 70vh;//85vh;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0;
        align-items: start;

        @media (min-width: 1025px) {
          align-items: center;
        }

        p {
          @include calloutFont();
          color: $gold;

          @media (max-width: 1024px) {
            align-items: center;
            padding-bottom: 30px;
          }
        }
      }

      .right {
        .right-border-block {
          @media (min-width: 1025px) {
            display: flex;
          }

          position: relative;
          flex-wrap: wrap;
          column-gap: 30px;
          text-align: left;
          margin-bottom: 0;

          .pillar {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 1.5rem);

            @media (min-width: 1025px) {
              max-width: 250px;
            }

            h2 {
              @include fontDefault($mercury, 400);
              font-size: 30px;
              color: $blue;
              margin-top: 0;
            }

            p {
              @include fontSmall($gotham, 400);
              font-size: 14px;
              line-height: 25px;
              color: $blue;
            }
          }

          @media (min-width: 1025px) {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: -1.5rem;
              width: 1px;
              height: 100%;
              background-color: $gold;
            }
          }
        }
      }
    }
  }

  .home-section_message {
    .message-bg {
      aspect-ratio: 144 / 95;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;

      display: flex;
      align-items: center;
      justify-content: center;

      .message {
        @include fontLarge($archer, 400);
        font-size: 30px;
        @media (min-width: 1025px) {
          font-size: 73px;
        }
      }
    }
  }

  .home-section_image-carousel {
    .carousel-container {
      aspect-ratio: 1440/799;
      position: relative;
      overflow: hidden;

      .images {
        position: absolute;
        position: absolute;
        top: 0px;
        left: 0px;
        overflow: hidden;
        white-space: nowrap;
        height: 100%;

        transition: transform 1.5s ease;

        &.moveLeft1 {
          // animation: fullMoveLeft1 forwards 1 1.5s;
          transform: translateX(-100vw);
        }
        &.moveLeft2 {
          // animation: fullMoveLeft1 forwards 1 1.5s;
          transform: translateX(-200vw);
        }

        &.moveRight1 {
          // animation: fullMoveRight1 forwards 1 1.5s;
          transform: translateX(-100vw);
        }
        &.moveRight2 {
          // animation: fullMoveRight1 forwards 1 1.5s;
          transform: translateX(0);
        }

        img {
          height: 100%;
          // animation: moveInR forwards 1 1.5s, moveInL forwards 1 1.5s 3s;
        }
      }

      @keyframes fullMoveLeft1 {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-50%);
        }
      }
      // @keyframes fullMoveLeft2 {
      //   0% {
      //     transform: translateX(-50%);
      //   }
      //   100% {
      //     transform: translateX(-100%);
      //   }
      // }

      @keyframes fullMoveRight1 {
        0% {
          transform: translateX(-50%);
        }
        100% {
          transform: translateX(0%);
        }
      }

      @keyframes moveInL {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
      @keyframes moveInR {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0%);
        }
      }
    }
  }
}
