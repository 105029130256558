.carousel {
  aspect-ratio: 1440/799;
  position: relative;
  overflow: hidden;

  &.post {
    margin-left: -40px;
    margin-right: -40px;
    padding-top: 90px;
  }

  .images {
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    white-space: nowrap;
    height: 100%;

    transition: transform 1.5s ease;

    &.img1 {
      transform: translateX(0);
    }

    &.img2 {
      transform: translateX(-100vw);
    }

    &.img3 {
      transform: translateX(-200vw);
    }

    &.img4 {
      transform: translateX(-300vw);
    }

    @keyframes over {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-50%);
      }
    }

    img {
      height: 100%;
    }
  }

  .caption {
    position: absolute;
    bottom: 50px;
    right: 50px;

    .span {
      @include fontMedium($mercury, 500);
      font-size: 22px;
      line-height: 18px;
    }
  }

  .arrows {
    position: absolute;
    width: 125px;
    bottom: 50px;
    right: 50px;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    cursor: pointer;

    &.noshow {
      &.start {
        justify-content: end;
      }
      &.end {
        justify-content: start;
      }
    }

    .arrow {
      background-color: $white;
      border-radius: 100%;
      transition: hover 1.5s ease;
      &.prev {
        &:hover {
          content: url("../../images/icons/prev-arrow-white.svg");
          background-color: $gold;
        }
      }
      &.next {
        &:hover {
          content: url("../../images/icons/next-arrow-white.svg");
          background-color: $gold;
        }
      }

      &.noshow {
        display: none;
      }
    }
  }
}

.bear-react-carousel__nav-next-button,
.bear-react-carousel__nav-prev-button {
  opacity: 1 !important;
  width: 47px !important;
  height: 47px !important;
  background-color: $white !important;

  img:hover {
    content: url("../../images/icons/prev-arrow-white.svg") !important;
    background-color: $gold !important;
  }
}

.bear-react-carousel__nav-next-button {
  bottom: 50px !important;
  top: unset !important;
  right: 50px !important;
}

.bear-react-carousel__nav-prev-button {
  top: unset !important;
  bottom: 50px !important;
  right: 130px !important;
  left: unset !important;
}
