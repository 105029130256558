@mixin fontXLarge($name: $archer, $weight: normal, $style: normal) {
  font-family: $name;
  font-style: $style;
  font-size: 4rem;
  font-weight: $weight;
  color: $white;
}

@mixin fontLarge($name: $archer, $weight: normal, $style: normal) {
  font-family: $name;
  font-style: $style;
  font-size: 3rem;
  font-weight: $weight;
  color: $white;
}

@mixin fontMedium($name: $archer, $weight: normal, $style: normal) {
  font-family: $name;
  font-style: $style;
  font-size: 2rem;
  font-weight: $weight;
  color: $white;
}

@mixin fontDefault($name: $archer, $weight: normal, $style: normal) {
  font-family: $name;
  font-style: $style;
  font-size: 1.5rem;
  font-weight: $weight;
  color: $white;
}

@mixin fontSmall($name: $archer, $weight: normal, $style: normal) {
  font-family: $name;
  font-style: $style;
  font-size: 1rem;
  font-weight: $weight;
  color: $white;
}

@mixin titleFont($name: $ringside) {
  font-family: $name;
  font-size: 40px;
  font-weight: 800;
  line-height: 90%;
  text-align: left;
  text-transform: uppercase;
  color: $white;

  .first-line {
    font-weight: 400;
  }

  @media (min-width: $max_mobile) {
    font-size: 45px;
  }

  @media (min-width: $max_tablet) {
    font-size: 65px;
  }

  @media (min-width: $min_desktop) {
    font-size: 85px;
  }

  @media (min-width: $min_large) {
    font-size: 107px;
    line-height: 91px;
  }
}

@mixin subheadFont($name: $mercury) {
  font-family: $name;
  font-size: 39px;
  font-weight: 400;
  line-height: 42px;
  color: $gold;
}

@mixin calloutFont($name: $gotham) {
  font-family: $name;
  font-size: 10px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.31em;
  text-transform: uppercase;
  color: $white;
}

@mixin bodyFont($name: $gotham) {
  font-family: $name;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: $blue;
}

@mixin linkFont($name: $archer) {
  font-family: $name;
  font-size: 19px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-decoration: none;
  color: $blue;
}
