.donors {
  .donors-section_intro {
    margin-bottom: 75px;

    @media (min-width: 1025px) {
      margin-bottom: 150px;
    }

    .hero-bg {
      aspect-ratio: 32 / 15;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
      padding: 0 40px 0 40px;

      @media (min-width: 1025px) {
        padding: 40px 40px 0 40px;
      }
    }

    .heading {
      @include fontLarge($ringside, 400);
      font-size: 95px;
      // line-height: 86px;
      color: $blue;
      text-transform: uppercase;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;//70px;//90px;
      // margin-top: 20px;

      @media (max-width: 1200px) {
        margin-bottom: 60px;
        margin-top: 0;
      }
    }

    .intro-text {
      @include fontDefault($gotham, 400);
      font-size: 14px;
      line-height: 25px;
      color: $blue;
      text-align: left;

      @media (min-width: 1025px) {
        width: 50%;
        margin: 0 auto;
      }

      @media (min-width: 1200px) {
        width: 40%;
      }
    }

    .header {
      .nav-link {
        color: $blue;
        background-color: $off_white;
      }
      .donate-button {
        color: $blue;
        background-color: $off_white;

        &:hover {
          color: $white;
          background-color: $gold;
        }
      }
    }
  }

  .donors-section_names {
    margin-bottom: 75px;
    position: relative;

    .vertical-title {
      @include calloutFont();
      color: $blue;
      position: absolute;
      top: 255px;
      left: 2%;
      transform: rotate(90deg);
    }

    .letter-section {
      text-align: left;
      padding: 50px 40px;

      @media (min-width: 1025px) {
        padding: 0 20% 50px;
      }

      .letter-heading {
        @include fontLarge($archer, 400);
        font-size: 85px;
        line-height: 85px;
        color: $gold;
        letter-spacing: 0.05em;
        margin-top: 0;
        margin-bottom: 30px;

        @media (min-width: 1025px) {
          font-size: 109px;
          line-height: 109px;
        }
      }

      .name-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media (min-width: 1025px) {
          grid-template-columns: repeat(3, 1fr);
        }

        .donor-name {
          @include fontDefault($gotham, 400);
          font-size: 14px;
          line-height: 30px;
          color: $blue;
          margin: 0;

          @media (max-width: 1024px) {
            margin-bottom: 15px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  .halfway_message {
    .message-bg {
      aspect-ratio: 144 / 95;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
      padding: 40px;
      margin: 100px 0;

      display: flex;
      align-items: center;
      justify-content: center;

      .message {
        @include fontLarge($archer, 400);
        font-size: 30px;

        @media (min-width: 1025px) {
          font-size: 73px;
        }
      }
    }
  }

  .donors-section_wrapup {
    .content {
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }

    .wrapup-bg {
      display: flex;
      flex-direction: column;
      justify-content: end;
      aspect-ratio: 16/19;
      background-size: contain;
      background-repeat: no-repeat;

      @media (min-width: 1025px) {
        aspect-ratio: 360 / 167;
        justify-content: center;
      }

      img {
        max-width: 100%;
      }

      .words-section {
        background-color: $blue;
      }

      p {
        @include fontLarge($mercury, 500);
        font-size: 17px;
        line-height: 30px;
        margin-top: 5px;
        margin-bottom: 15px;

        @media (min-width: 1025px) {
          font-size: 28px;
          margin-top: 18px;
          margin-bottom: 28px;
        }

        &.title {
          @include fontLarge($gotham, 700);
          font-size: 30px;
          margin-top: 30px;
          margin-bottom: 0;
          letter-spacing: 5px;

          @media (min-width: 1025px) {
            font-size: 30px;
            margin-top: 0;
          }

          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
  }
}
