.header {
  @media (max-width: 1024px) {
    padding: 40px 0;
  }

  @media (min-width: 1025px) {
    display: flex;
  }
  align-items: center;
  justify-content: space-between;

  .logo-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @media (min-width: 1025px) {
      justify-content: left;
      margin-bottom: 0;
    }

    .logo {
      max-width: 107px;
    }
  }

  .hover-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-grow: 2;

    @media (max-width: 1025px) {
      flex-direction: column;
    }
  }

  .nav-link-container {
    .nav-link {
      @include calloutFont();
      text-decoration: none;

      @media (max-width: 1024px) {
        color: $blue;
      }

      span {
        &:after {
          display: block;
          content: "";
          border-bottom: solid 3px $gold;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
          transform-origin: 100% 50%;
        }

        &:hover:after {
          transform: scaleX(1);
          transform-origin: 0 50%;
        }
      }
    }
  }

  .circle-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    @media (min-width: 1025px) {
      justify-content: right;
    }

    .donate-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include fontSmall($archer, 400);
      height: 92px;
      width: 92px;
      border-radius: 50%;
      color: $white;
      background-color: transparent;
      outline: none;
      transition: all 0.2s ease-in;
      border: 2px $gold solid;
      text-decoration: none;

      @media (max-width: 1024px) {
        color: $blue;
      }

      &:hover {
        color: $blue;
        background-color: $white;
      }
    }
  }

  .ham {
    position: absolute;
    top: 80px;
    right: 20px;
    z-index: 999;

    img {
      max-width: 80%;
    }
  }

  .mobile-nav {
    &.collapsed {
      display: none;
    }
  }
}
