.donate {
  .donate-section_intro {
    .hero-bg {
      aspect-ratio: 32 / 15;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
      padding: 40px 40px 0 40px;
    }

    .heading {
      @include fontLarge($ringside, 100);
      font-size: 60px;
      margin-bottom: 35px;

      // line-height: 86px;

      @media (min-width: 1025px) {
        font-size: 95px;
        margin-bottom: 50px;//70px;
      }

      color: $blue;
      text-transform: uppercase;
      width: max-content;
      margin-left: auto;
      margin-right: auto;
    }

    .header {
      .nav-link {
        color: $blue;
        background-color: $off_white;
      }
      .donate-button {
        color: $blue;
        background-color: $off_white;

        &:hover {
          color: $white;
          background-color: $gold;
        }
      }
    }

    .intro-blurb {
      @include fontLarge($gotham, 400);
      font-size: 14px;
      line-height: 25px;
      color: $blue;
      margin: 0 auto;
      max-width: 515px;
      text-align: left;
    }
  }

  .donate-section_giving-levels {
    @media (max-width: 1024px) {
      padding: 40px;
    }

    .giving-levels-bg {
      aspect-ratio: 12 / 13;//360 / 239;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
      // padding: 0 40px;

      .levels-columns {
        @media (min-width: 1025px) {
          display: flex;
          padding: 100px;
          // gap: 70px;
        }

        img {
          max-width: 90%;

          @media (max-width: 1024px) {
            margin-bottom: 30px;
          }
        }

        .left {
          flex-basis: 55%;

          @media (min-width: 1025px) {
            text-align: left;
          }
        }

        .right {
          flex-basis: 45%;
          max-width: 500px;

          .title {
            @include fontDefault($mercury, 400);
            font-size: 30px;
            line-height: 35px;
            color: $blue;
            text-align: left;
            margin-top: 0;
          }

          .sub {
            @include fontSmall($gotham, 400);
            font-size: 14px;
            line-height: 25px;
            color: $blue;
            text-align: left;
          }

          .contact {
            text-align: left;

            a {
              text-decoration: none;
              border-bottom: 1px solid $gold;
            }

            .name {
              @include fontDefault($gotham, 700);
              font-size: 14px;
              line-height: 20px;
              color: $blue;
              padding-bottom: 20px;
            }

            .email,
            .phone {
              @include fontDefault($gotham, 400);
              font-size: 14px;
              line-height: 20px;
              color: $gold;
            }

            .top {
              text-align: center;
              display: flex;
              text-align: left;

              @media (max-width: 1024px) {
                gap: 45px;
              }

              div {
                flex-basis: 50%;
                margin-bottom: 50px;
              }
            }

            .bottom {
              text-align: center;
              display: flex;
              text-align: left;

              @media (max-width: 1024px) {
                gap: 45px;
              }

              div {
                flex-basis: 50%;

                @media (max-width: 1024px) {
                  margin-bottom: 50px;
                }
              }
            }
          }
        }
      }

      h3 {
        @include fontDefault($mercury, 400);
        color: $gold;
        padding: 55px 0;
        margin: 0;

        @media (min-width: 1025px) {
          // padding: 95px 0;
          padding-top: 95px;
          padding-bottom: 0;
        }
      }

      img {
        max-height: 570px;
      }
    }

    .cta-button {
      margin-top: 45px;
    }
  }

  .donate-section_donate {
    .donate-links {
      @media (min-width: 1025px) {
        display: flex;
      }

      .donate-links-content {
        position: relative;
        width: 100%;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        aspect-ratio: 0.9 / 1;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-size: auto 110%;
        }

        .donate-links-bg {
          position: relative;
          width: 100%;
        }

        .content {
          position: absolute;
          top: 40%;

          &.left {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          }

          &.right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          }

          p {
            @include fontDefault($mercury, 500);
            font-size: 50px;
            line-height: 10px;
          }

          .donate-button {
            @include linkFont();
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 17px;
            color: $blue;
            font-size: 20px;
            font-weight: 700;

            border: 2px #c89211 solid;
            border-radius: 30px;
            padding: 10px;
            max-width: max-content;

            &:hover {
              color: $gold;
            }
          }
        }
      }
    }
  }

  .donate-section_how-to-give {
    .how-to-give-bg {
      aspect-ratio: 2888 / 1845;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -999;
      margin-top: 80px;

      @media (min-width: 1025px) {
        margin-top: 140px;
      }

      .title {
        @include fontSmall($gotham, 700);
        font-size: 10px;
        color: $gold;
        letter-spacing: 0.31em;
        text-transform: uppercase;
      }

      .giving-options {
        @media (max-width: 1024px) {
          padding: 0 40px;
        }
      }

      .how-columns {
        @media (max-width: 1024px) {
          .column {
            max-width: 80%;
            margin: 0 auto;
          }
        }

        .options {
          text-align: left;
          max-width: 580px;

          .cash,
          .securities,
          .ira,
          .life,
          .real,
          .retained,
          .charitable,
          .bequest,
          .retirement,
          .crypto {
            @include fontDefault($mercury, 400);
            font-size: 24px;
            color: $blue;
            padding-bottom: 1rem;
            cursor: pointer;

            &:nth-child(1) {
              margin-top: 0;
            }

            p {
              margin-top: 1rem;
              margin-bottom: 0;
            }
          }

          .cash-group,
          .securities-group,
          .ira-group,
          .life-group,
          .real-group,
          .retained-group,
          .charitable-group,
          .bequest-group,
          .retirement-group,
          .crypto-group {
            border-bottom: 1px solid $gold;
          }

          .cash-description,
          .securities-description,
          .ira-description,
          .life-description,
          .real-description,
          .retained-description,
          .charitable-description,
          .bequest-description,
          .retirement-description,
          .crypto-description {
            p {
              @include fontSmall($gotham, 400);
              font-size: 14px;
              line-height: 25px;
              color: $blue;
            }

            &.collapsed {
              display: none;
            }
          }
        }
      }
    }
  }

  .contact-section_leadership {
    padding: 0 40px;
    // margin-bottom: 95px;

    .headshots {
      @media (min-width: 1025px) {
        display: flex;
        // justify-content: space-between;
        justify-content: space-around;
        padding: 0 85px;
      }

      .leader {
        position: relative;
        margin-top: 50px;

        @media (min-width: 1025px) {
          width: 230px;
          margin-top: 0;
        }

        img {
          max-width: 200px;
          margin-bottom: 20px;
        }

        .leader-info {
          position: relative;
          text-align: left;

          .name {
            @include fontDefault($ringside, 600);
            font-size: 24px;
            line-height: 24px;
            color: $blue;
            margin: 0 0 5px 0;
            padding-top: 6px;
          }

          .title {
            @include fontDefault($gotham, 700);
            font-size: 12px;
            color: $blue;
          }

          .contact-info {
            position: relative;
            margin-top: 30px;
            display: flex;
            flex-direction: column;

            a {
              @include bodyFont();
              font-size: 12px;
              line-height: 22px;
              color: $gold;
            }

            &:before {
              content: "";
              position: absolute;
              top: -15px;
              left: 0;
              width: 17px;
              height: 1px;
              background-color: $blue;
            }
          }

          &:before {
            content: "";
            position: absolute;
            top: -7px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $blue;
          }
        }
      }
    }
  }
}
