.splash {
  .hero {
    position: relative;
    height: 600px;
    margin-bottom: 1rem;
    padding-top: 1rem;

    @media (min-width: $min_desktop) {
      margin-bottom: 3rem;
    }

    animation: fadein 1s ease-in;

    @media (min-width: $max_tablet) {
      height: 770px;
    }

    @media (min-width: $min_large) {
      height: 880px;
    }

    .capital-campaign-text {
      @include fontSmall($gotham, 600);
      letter-spacing: 0.28em;
      text-transform: uppercase;
      margin-bottom: 3rem;
      display: none;

      @media (min-width: $min_desktop) {
        display: block;
        position: inherit;
        right: 0;
        top: 0;
        font-size: 0.85rem;
      }
    }

    .shield-portrait {
      max-height: 450px;
      position: absolute;
      max-width: 80%;
      left: 10%;
      top: 12rem;

      @media (min-width: 600px) {
        left: 25%;
      }

      @media (min-width: $min_desktop) {
        top: unset;
      }

      @media (min-width: $min_large) {
        max-height: 580px;
      }

      animation-name: fade;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 45s;

      &:nth-of-type(1) {
        animation-delay: 45s;
      }
      &:nth-of-type(2) {
        animation-delay: 40s;
      }
      &:nth-of-type(3) {
        animation-delay: 35s;
      }
      &:nth-of-type(4) {
        animation-delay: 30s;
      }
      &:nth-of-type(5) {
        animation-delay: 25s;
      }
      &:nth-of-type(6) {
        animation-delay: 20s;
      }
      &:nth-of-type(7) {
        animation-delay: 15s;
      }
      &:nth-of-type(8) {
        animation-delay: 10s;
      }
      &:nth-of-type(9) {
        animation-delay: 5s;
      }
      &:nth-of-type(10) {
        animation-delay: 0;
      }
    }

    @keyframes fade {
      0% {
        opacity:1;
      }
      8% {
        opacity:1;
      }
      10% {
        opacity:0;
      }
      98% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }


    @keyframes fadein {
      0% { opacity: 0; }
      50% { opacity: 0.5; }
      100% { opacity: 1; }
    }

    .shield-logo {
      position: relative;
      max-height: 130px;

      @media (min-width: $min_desktop) {
        max-height: 250px;
        position: absolute;
        top: 0;
        left: 0;
        max-height: 540px;
      }

      @media (min-width: $min_large) {
        max-height: 580px;
      }
    }

    .shield-outline {
      position: absolute;
      top: 11rem;
      left: 5rem;
      max-width: 80%;

      @media (min-width: 600px) {
        right: -1rem;
        left: unset;
        max-width: 70%;
      }

      @media (min-width: $max_tablet) {
        right: 4rem;
        left: unset;
        max-width: 60%;
      }

      @media (min-width: $min_desktop) {
        top: 0;
        right: -7rem;
        left: unset;
        height: 100%;
        max-width: 100%;
      }

      @media (min-width: $min_large) {
        right: -3rem;
        height: initial;
      }
    }

    .title-container {
      position: absolute;
      bottom: 0;
      
      @media (min-width: $max_tablet) {
        right: 15rem;
      }

      @media (min-width: $min_desktop) {
        right: unset;
      }
  
      .title {
        position: relative;
        @include fontXLarge($ringside, 800);
        font-size: 40px;
        line-height: 90%;
        text-align: left;
        text-transform: uppercase;
        margin: 0;
        padding-top: 3rem;

        @media (min-width: $max_mobile) {
          font-size: 45px;
        }

        @media (min-width: $max_tablet) {
          font-size: 65px;
        }

        @media (min-width: $min_desktop) {
          font-size: 85px;
        }

        @media (min-width: $min_large) {
          font-size: 120px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 1rem;
          left: 0;
          width: 60px;
          height: 10px;
          background-color: $gold;

          @media (min-width: $min_desktop) {
            top: 0;
            width: 145px;
            height: 1rem;
          }
        }
      }
    }
  }

  .sign-up-container {
    position: relative;
    max-width: 545px;
    min-height: 350px;
    margin: 0 auto 3rem;
    text-align: left;

    @media (min-width: $min_desktop) {
      margin: 0 auto 6rem; 
    }

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      right: 105%;
      width: 1px;
      height: 100%;
      background-color: $gold;
    }

    .sign-up-blurb {
      @include fontSmall($mercury, 500);
      font-size: 17px;
      line-height: 32px;
    }
  }
}