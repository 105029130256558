// fonts
$archer: "Archer A", "Archer B";
$gotham: "Gotham A", "Gotham B";
$mercury: "Mercury Text G1 A", "Mercury Text G1 B";
$ringside: "Ringside Condensed A", "Ringside Condensed B";

// colors
$white: #ffffff;
$black: #000000;
$blue: #003057;
$gold: #c89211;
$cream: #fefdf5;
$dark_blue: #003057;
$light_gold: #f7e79c;
$off_white: #fefdf5;
$darker_gold: #ac7e0f;

// screen sizes
$max_small_mobile: 375px;
$max_mobile: 414px;
$min_tablet: 481px;
$max_tablet: 768px;
$min_laptop: 769px;
$max_laptop: 1024px;
$min_desktop: 1025px;
$max_desktop: 1200px;
$min_large: 1201px;
