.contact {
  padding-top: 40px;

  .header {
    @media (max-width: 1024px) {
      padding: 0 !important;
    }
  }

  .contact-section_intro {
    padding: 0 40px 0 40px;
    margin-bottom: 75px;

    @media (min-width: 1025px) {
      margin-bottom: 150px;
    }

    .heading {
      @include fontLarge($ringside, 700);
      font-size: 65px;
      color: $blue;
      text-transform: uppercase;
      border-bottom: 1px solid $gold;
      width: max-content;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 90px;
    }

    .header {
      .nav-link {
        color: $blue;
        background-color: $off_white;
      }
      .donate-button {
        color: $blue;
        background-color: $off_white;

        &:hover {
          color: $white;
          background-color: $gold;
        }
      }
    }

    .columns {
      @media (max-width: 1024px) {
        display: block;
      }
    }

    .intro-text {
      @media (max-width: 1024px) {
        padding: 0;
      }

      padding: 0 200px;
      text-align: left;

      .left {
        flex-basis: calc(52% - 1.5rem);

        .intro-blurb {
          @include fontLarge($mercury, 400);
          font-size: 30px;
          line-height: 40px;
          color: $gold;
          margin: 0;
        }
      }

      .right {
        flex-basis: calc(40% - 1.5rem);

        .school-address {
          @include fontSmall($gotham, 400);
          font-size: 14px;
          line-height: 25px;
          color: $blue;
        }

        .email {
          @include fontSmall($gotham, 400);
          font-size: 14px;
          line-height: 25px;
          color: $gold;
        }
      }
    }
  }

  .contact-section_leadership {
    padding: 0 40px;
    margin-bottom: 95px;

    .headshots {
      @media (min-width: 1025px) {
        display: flex;
        // justify-content: space-between;
        justify-content: space-around;
        padding: 0 85px;
      }

      .leader {
        position: relative;
        margin-top: 50px;

        @media (min-width: 1025px) {
          width: 230px;
          margin-top: 0;
        }

        img {
          max-width: 200px;
          margin-bottom: 20px;
        }

        .leader-info {
          position: relative;
          text-align: left;

          .name {
            @include fontDefault($ringside, 600);
            font-size: 24px;
            line-height: 24px;
            color: $blue;
            margin: 0 0 5px 0;
            padding-top: 6px;
          }

          .title {
            @include fontDefault($gotham, 700);
            font-size: 12px;
            color: $blue;
          }

          .contact-info {
            position: relative;
            margin-top: 30px;
            display: flex;
            flex-direction: column;

            a {
              @include bodyFont();
              font-size: 12px;
              line-height: 22px;
              color: $gold;
            }

            &:before {
              content: "";
              position: absolute;
              top: -15px;
              left: 0;
              width: 17px;
              height: 1px;
              background-color: $blue;
            }
          }

          &:before {
            content: "";
            position: absolute;
            top: -7px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $blue;
          }
        }
      }
    }
  }

  .contact-section_wrapup {
    .content {
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }
    .wrapup-bg {
      display: flex;
      flex-direction: column;
      justify-content: end;
      aspect-ratio: 16/19;
      background-size: contain;
      background-repeat: no-repeat;

      @media (min-width: 1025px) {
        aspect-ratio: 360 / 167;
        justify-content: center;
      }

      img {
        max-width: 100%;
      }

      .words-section {
        background-color: $blue;
      }

      p {
        @include fontLarge($mercury, 500);
        font-size: 17px;
        line-height: 30px;
        margin-top: 5px;
        margin-bottom: 15px;

        @media (min-width: 1025px) {
          font-size: 28px;
          margin-top: 18px;
          margin-bottom: 28px;
        }

        // @media (max-width: 1024px) {
        //   margin-bottom: 20px;
        //   margin-top: 0;
        // }

        &.title {
          @include fontLarge($gotham, 700);
          font-size: 30px;
          margin-top: 30px;
          margin-bottom: 0;
          letter-spacing: 5px;

          @media (min-width: 1025px) {
            font-size: 30px;
            margin-top: 0;
          }

          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
  }
}
