.subscribe {
  .sign-up-form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: start;
    column-gap: 2rem;
    padding: 1rem 0 0;

    @media (min-width: $min_desktop) {
      flex-direction: row;
      align-items: center;
    }

    .sub-input {
      @include fontSmall($gotham, 500);
      color: $blue;
      height: 55px;
      width: 95%;
      border-radius: 3px;
      outline: none;
      border: 1px $gold solid;
      padding-left: 1rem;
      background-color: transparent;

      @media (min-width: $min_desktop) {
        width: 450px;
      }

      &::placeholder {
        letter-spacing: -0.02em;
        color: $blue;
        text-align: left;
        font-size: 1rem;
      }

      &:focus {
        opacity: 1;

        &::placeholder {
          opacity: 0;
        }
      }
    }

    .sub-button {
      .footer-submit {
        @include fontSmall($archer, 500);
        font-size: 20px;
        color: $blue;

        @media (min-width: 1025px) {
          padding: 0 1rem;
        }

        position: relative;
        text-decoration: none;
        display: inline-block;

        &:after {
          display: block;
          content: "";
          border-bottom: solid 1px $gold;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
          transform-origin: 100% 50%;
        }

        &:hover:after {
          transform: scaleX(1);
          transform-origin: 0 50%;
        }
      }

      background-color: transparent;
      border-width: 0;
      outline: none;
      transition: all 0.2s ease-in;
      width: 80px;

      @media (min-width: $max-tablet) {
      }

      &:hover {
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .email-error {
    @include fontSmall($gotham, 700);
    font-size: 18px;
    color: $gold;
    font-weight: 700;
  }

  .submit-message {
    @include fontMedium($archer, 500);
    font-size: 28px;
    color: $gold;
  }
}
