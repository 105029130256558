body {
  margin: 0;
  background-color: $white;

  h3 {
    @include fontSmall($gotham, 700);
  }
}

.inline-link {
  color: initial;
}

.cta-button {
  @include linkFont();
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
  font-size: 17px;
  line-height: 32px;
  color: $blue;
  font-weight: 700;
  transition: all 0.2s ease-in;

  background-color: $white;
  border: 1.5px $gold solid;
  border-radius: 30px;
  padding: 7px 24px 10px 33px;
  max-width: max-content;

  &.darker {
    border-color: $darker_gold;
  }

  &.centered {
    margin: 0 auto;
  }

  &.margin-bottom {
    @media (max-width: 1024px) {
      margin-bottom: 65px;
    }
  }

  img {
    content: url("../../images/icons/right-arrow.svg");
  }

  &:hover {
    img {
      content: url("../../images/icons/right-arrow_white.svg");
    }
  }

  &:hover {
    color: $white;
    background-color: $gold;

    &.darker {
      background-color: $darker_gold;
    }
  }
}

.testimonials {
  position: relative;
  min-height: 400px;
  background-color: $off_white;

  @media (max-width: 1024px) {
    padding: 0 40px;
  }

  .shield {
    position: absolute;
    top: 0;
    right: 0;
  }

  .anchor {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 38px;
    padding-right: 58px;
  }

  .testimonial-block {
    width: 90%;
    margin: 0 auto;
    text-align: left;
    padding: 75px 0;

    @media (min-width: 1025px) {
      width: 45%;
      padding: 175px 0;
    }

    .quote {
      @include fontDefault($archer, 400);
      font-size: 25px;
      line-height: 35px;
      position: relative;
      color: $blue;

      // .quotation-mark {
      //   position: absolute;
      //   top: 0;
      //   left: -64px;
      // }
    }

    .author {
      @include fontSmall($gotham);
      line-height: 16px;
      padding-top: 50px;
      // display: flex;
      // flex-direction: column;
      color: $blue;

      .name {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .about {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}

.hoban-capital-campaign {
  text-align: center;
  border-top: 10px solid $gold;

  .main-container {
    max-width: 1024px;
    margin: 0 auto;
  }

  .block-quote {
    position: relative;
    margin: 75px 0;

    .text {
      @include fontDefault();
      font-size: 24px;
      line-height: 35px;
      color: $gold;
    }

    .author {
      @include calloutFont();
      color: $gold;
    }

    &:before {
      content: "";
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $gold;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $gold;
    }
  }
}

.loader-container {
  height: 70vh;
  // display: flex;
  // align-items: center;

  // .loader {
  //   border: 16px solid $light_gold;
  //   border-radius: 50%;
  //   border-top: 16px solid $blue;
  //   width: 120px;
  //   height: 120px;
  //   animation: spin 2s linear infinite;
  //   margin: 0 auto;
  // }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
