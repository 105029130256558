.footer {
  background-color: $blue;

  .top-section {
    background-size: cover;
    z-index: -999;
    aspect-ratio: 1440 / 623;
    background-color: $white;

    @media (min-width: 1025px) {
      background-repeat: no-repeat;
    }

    .top-content-container {
      padding: 40px;

      @media (min-width: 1025px) {
        padding: 60px 125px;
      }

      .donate-container {
        p {
          max-width: 420px;
          @include fontSmall($gotham, 400);
          font-size: 14px;
          line-height: 25px;
          letter-spacing: -0.01em;
          color: $blue;
        }

        .donate-button {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          @include fontSmall($archer, 400);
          height: 92px;
          width: 92px;
          border-radius: 50%;
          color: $blue;
          background-color: transparent;
          outline: none;
          transition: all 0.2s ease-in;
          border: 2px $gold solid;
          text-decoration: none;

          &:hover {
            background-color: $gold;
            color: $white;
          }
        }
      }

      .links-container {
        @media (min-width: 1025px) {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
        }

        margin-bottom: 40px;

        a {
          text-decoration: none;
          color: $blue !important;

          span {
            position: relative;
            text-decoration: none;
            display: inline-block;

            &:after {
              display: block;
              content: "";
              border-bottom: solid 1px $gold;
              transform: scaleX(0);
              transition: transform 0.3s ease-in-out;
              transform-origin: 100% 50%;
            }

            &:hover:after {
              transform: scaleX(1);
              transform-origin: 0 50%;
            }
          }
        }

        .site-links {
          display: flex;
          flex-direction: column;

          .link {
            @include fontMedium($mercury, 400);
            font-size: 37px;
            line-height: 60px;
            letter-spacing: -0.02em;

            span {
              line-height: 50px;
            }
          }
        }

        .social-links {
          display: flex;
          flex-direction: column;
          min-width: 75px;
          margin-top: 9px;

          .link {
            @include fontDefault($gotham, 400);
            font-size: 14px;
            line-height: 30px;
          }
        }
      }
    }

    .subscribe-padding {
      padding: 40px;

      @media (min-width: 1025px) {
        padding: 0 125px;
      }

      .subscribe-container {
        position: relative;

        @media (min-width: 1025px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 90px;
        }

        .subscribe-message {
          max-width: 400px;
          @include fontMedium($archer, 400);
          font-size: 40px;
          line-height: 40px;
          color: $blue;
        }

        &:after {
          content: "";
          position: absolute;
          border-bottom: 1px solid $gold;
          width: 100%;
          bottom: 135%;

          @media (min-width: $min_desktop) {
          }
        }
      }
    }
  }

  .bottom-section {
    margin: 0 50px;
    padding: 50px 0;

    @media (min-width: $max_desktop) {
      margin: 0 125px;
    }

    .bottom-content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      row-gap: 100px;

      .wordmark-container {
        width: 290px;

        img {
          width: 100%;
        }
      }

      .school-info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 30px;
        position: relative;

        @media (min-width: $min_desktop) {
          flex-direction: row;
        }

        .every-knight-logo {
          max-width: 300px;
          padding-top: 8px;

          @media (min-width: 1025px) {
            height: 100%;
          }
        }

        p {
          @include fontSmall($gotham, 500);
          font-size: 14px;
          line-height: 25px;
          letter-spacing: -0.01em;
          margin: 2px;
          color: $white;
          text-align: left;

          &:first-child {
            max-width: 425px;
          }
        }

        &:after {
          content: "";
          position: absolute;
          // border: 1px solid $black;
          width: 100%;
          top: 123%;

          @media (min-width: $min_desktop) {
            top: 145%;
          }
        }
      }
    }
  }
}
