.donor-voices {
  .donor-voices-section_hero {
    .hero-bg {
      aspect-ratio: 1440 / 1123;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -999;

      .padding {
        @media (min-width: 1025px) {
          padding: 40px 40px 0 40px;
        }

        .subhead {
          @include calloutFont();
          color: $gold;
          text-transform: uppercase;
          padding-top: 50px;
          margin-top: 0;

          @media (min-width: 1025px) {
            margin-top: 50px;
            padding-top: 0;
          }
        }

        .heading {
          @include fontLarge($mercury, 400);
          font-size: 42px;
          line-height: 50px;
          max-width: 600px;
          margin: 20px 34px 110px;

          @media (min-width: 1025px) {
            font-size: 67px;
            line-height: 75px;
            margin: 20px auto 110px;
          }
        }

        .columns {
          @media (min-width: 1025px) {
            margin: 0 10%;
          }

          @media (max-width: 1025px) {
            flex-direction: column;
            gap: 85px;

            .left {
              .img-container {
                .student-shield {
                  position: static;
                  max-height: 425px;
                }
              }
            }

            .testimonials {
              margin-bottom: 46.6px;
            }

            .testimonial-block {
              margin-left: auto;
            }
          }
        }

        .left {
          .img-container {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex: 1;
            height: 100%;

            .student-shield {
              flex: 0 0 auto;
              vertical-align: bottom;
              max-width: 85%;
              max-height: 100%;

              position: absolute; // make the image absolute
              left: 0; // and position it in the center
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;

              @media (min-width: 1025px) {
                max-width: 100%;
              }
            }
          }
        }

        .testimonials {
          background-color: transparent;
        }

        .testimonial-block {
          padding: 0;
          width: 85%;
          margin-left: 0;
          margin-right: auto;

          .quote {
            font-size: 22px;
            line-height: 35px;
            color: $white;
            margin-bottom: 0;

            .end-quotation-mark {
              padding-left: calc(100% - 32px);
            }
          }

          .author {
            display: flex;
            flex-direction: column;
            padding-top: 15px;

            .name,
            .about {
              font-size: 14px;
              line-height: 20px;
              color: $white;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .donor-voices-section_voices {
    .voices-bg {
      aspect-ratio: 2880 / 2156;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -999;
      background-color: $off_white;

      &.top {
        position: relative;

        .corner-shield {
          position: absolute;
          top: 150px;
          right: 40px;

          @media (max-width: 1280px) {
            top: 75px;
          }
        }

        .corner-curve {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &.donors {
        aspect-ratio: unset;

        .padding {
          //padding: 50px 40px 0;

          @media (min-width: 1025px) {
            padding: 65px 40px;
          }
        }
      }

      .padding {
        padding: 50px 40px 0;
        text-align: left;

        @media (min-width: 1280px) {
          padding: 125px 40px 0;
        }

        .voice-type-container {
          max-width: 600px;
          margin: 0 auto;

          &.bottom-pad {
            @media (min-width: 1024px) {
              padding-bottom: 150px;
            }
          }

          .title {
            h2 {
              @include fontMedium($mercury, 500);
              font-size: 20px;
              line-height: 20px;
              color: $gold;

              @media (min-width: 1025px) {
                font-size: 30px;
                line-height: 30px;
              }
            }
          }

          .voice-content {
            .quote {
              p {
                @include bodyFont();
                font-weight: 400;
                font-size: 14px;
                line-height: 27px;
                letter-spacing: -0.01em;

                @media (min-width: 1025px) {
                  font-size: 16px;
                  line-height: 29px;
                }
              }
            }

            .author {
              padding-bottom: 50px;
              text-transform: uppercase;

              .name {
                @include bodyFont();
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.05em;

                @media (min-width: 1025px) {
                  font-size: 14px;
                  line-height: 20px;
                }
              }

              .about {
                @include bodyFont();
                font-weight: 400;
                font-size: 10px;
                line-height: 18px;
                letter-spacing: 0.05em;

                @media (min-width: 1025px) {
                  font-size: 12px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .donor-voices-section_message {
    .message-bg {
      aspect-ratio: 144 / 95;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -999;
      padding: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      .message {
        @include fontLarge($archer, 400);
        font-size: 30px;

        @media (min-width: 1025px) {
          font-size: 73px;
        }
      }
    }
  }

  .donor-voices-section_wrapup {
    .content {
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }
    .wrapup-bg {
      display: flex;
      flex-direction: column;
      justify-content: end;
      aspect-ratio: 16/19;
      background-size: contain;
      background-repeat: no-repeat;

      @media (min-width: 1025px) {
        aspect-ratio: 360 / 167;
        justify-content: center;
      }

      img {
        max-width: 100%;
      }

      .words-section {
        background-color: $blue;
      }

      p {
        @include fontLarge($mercury, 500);
        font-size: 17px;
        line-height: 30px;
        margin-top: 5px;
        margin-bottom: 15px;

        @media (min-width: 1025px) {
          font-size: 28px;
          margin-top: 18px;
          margin-bottom: 28px;
        }

        &.title {
          @include fontLarge($gotham, 700);
          font-size: 30px;
          margin-top: 30px;
          margin-bottom: 0;
          letter-spacing: 5px;

          @media (min-width: 1025px) {
            font-size: 30px;
            margin-top: 0;
          }

          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
  }
}
